import React from "react"
import { navigate } from "gatsby"

import { GatsbyPage, IBooking, IBookingInfos, IBilling } from "src/types"
import Layout from "src/layout"
import { Section } from "src/components/styled"
import { useCart, getKey } from "src/components/cart"
import { Content, Breadcrumbs, StepTitle, ShopTitle, Checkbox } from "src/components/shop"
import { formatEventDate } from "src/helpers/date"
import Form, { Row, TextInput, Infos, Label } from "src/components/Form"
import styled from "styled-components"

const getInfoKey = (info: IBookingInfos) => `${info.email}#${info.firstname}#${info.lastname}`
const hasDuplicates = (infos: IBookingInfos[]) => {
  const keys = new Set()
  return infos.some((info) => keys.size === keys.add(getInfoKey(info)).size)
}

const Legal = styled.div`
  margin: 4em 0 2em;
`

interface Values {
  billing: IBilling
  infos: IBooking["infos"][]
}

const OrderPage: GatsbyPage = () => {
  const { empty, bookings, presents, billing, getTotal, setBilling, setInfos } = useCart()

  const handleSubmit = (values: Values) => {
    setBilling(values.billing)
    if (bookings?.length) {
      const infosPerDate: Record<string, IBookingInfos[]> = {}
      const infos = values.infos.map((info, index) => {
        const { quantity, formula, date } = bookings[index]
        const people = quantity * formula.people
        info = info.slice(0, people) // in case we previously stored some more, restored by final form

        if (!infosPerDate[date.id]) {
          infosPerDate[date.id] = []
        }
        infosPerDate[date.id].push(...info)
        return info
      })
      setInfos(infos)
      for (const key in infosPerDate) {
        if (hasDuplicates(infosPerDate[key])) {
          alert("Chaque participant doit être unique par date")
          return
        }
      }
    }
    navigate("/paiement/")
  }

  const initialValues: Values = {
    billing,
    infos: bookings?.map(({ infos }) => infos),
  }

  return (
    <Layout title="Commande" noindex>
      <ShopTitle />
      <Section>
        {empty ? (
          <Content>
            <p>Votre panier est vide</p>
          </Content>
        ) : (
          <Form
            onSubmit={handleSubmit}
            submitLabel={getTotal() > 0 ? "Paiement" : "Continuer"}
            initialValues={initialValues}
          >
            <Breadcrumbs active="informations" />
            <StepTitle>Informations de facturation</StepTitle>
            <Row>
              <TextInput type="email" name="billing.email" placeholder="Adresse e-mail" required />
            </Row>
            <Row>
              <TextInput name="billing.firstname" placeholder="Prénom" required />
              <TextInput name="billing.lastname" placeholder="Nom" required />
            </Row>
            <Row>
              <TextInput name="billing.postcode" width="150px" placeholder="Code postal" required />
            </Row>
            {bookings?.length > 0 && (
              <>
                <StepTitle>Informations sur les participant·e·s</StepTitle>
                {bookings.map((article: IBooking, index) => (
                  <Infos key={getKey(article, index)}>
                    <Label>Formule : {article.formula.name}</Label>
                    {article.variant && <Label>Objet : {article.variant}</Label>}
                    <Label>Date : {formatEventDate(article.date)}</Label>
                    {Array.from({ length: article.quantity * article.formula.people }).map(
                      (_: undefined, i: number) => (
                        <React.Fragment key={i}>
                          {article.quantity > 1 && (
                            <div>
                              <br />
                              Participant·e {i + 1}
                            </div>
                          )}
                          <Row>
                            <TextInput
                              type="email"
                              name={`infos[${index}][${i}].email`}
                              placeholder="Adresse e-mail du·de la participant·e"
                              required
                            />
                            <TextInput
                              type="phone"
                              name={`infos[${index}][${i}].phone`}
                              placeholder="Téléphone du·de la participant·e"
                              required
                            />
                          </Row>
                          <Row>
                            <TextInput
                              name={`infos[${index}][${i}].firstname`}
                              placeholder="Prénom du·de la participant·e"
                              required
                            />
                            <TextInput
                              name={`infos[${index}][${i}].lastname`}
                              placeholder="Nom du·de la participant·e"
                              required
                            />
                          </Row>
                          {article.formula.requiresDescription && (
                            <TextInput
                              name={`infos[${index}][${i}].description`}
                              multiline
                              placeholder="Décrivez votre projet"
                              required
                            />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </Infos>
                ))}
              </>
            )}
            {presents?.filter(({ mail }) => mail).length > 0 && (
              <>
                <StepTitle>Informations d’envoi des cartes cadeaux</StepTitle>
                <Infos>
                  <Row>
                    <TextInput name="billing.delivery.firstname" placeholder="Prénom" required />
                    <TextInput name="billing.delivery.lastname" placeholder="Nom" required />
                  </Row>
                  <Row>
                    <TextInput name="billing.delivery.address" width="100%" placeholder="Adresse postale" required />
                  </Row>
                  <Row>
                    <TextInput name="billing.delivery.postcode" width="150px" placeholder="Code postal" required />
                    <TextInput name="billing.delivery.city" placeholder="Ville" required />
                    <TextInput name="billing.delivery.country" placeholder="Pays" required />
                  </Row>
                </Infos>
              </>
            )}
            {bookings?.length > 0 && (
              <Legal>
                <Checkbox required>
                  J’ai pris connaissance des conditions d’échange et d’annulation de mon atelier : échange possible
                  jusqu’à 7 jours avant la date de l’atelier, passé ce délai l’atelier ne sera ni échangeable, ni
                  remboursable.
                </Checkbox>
              </Legal>
            )}
          </Form>
        )}
      </Section>
    </Layout>
  )
}

export default OrderPage
